<template>
    <div>
    
       
        <PaymentForm />
    

    </div>


    
    
</template>
<script>

import PaymentForm from '../components/PaymentForm.vue';

export default {
    name: 'CheckoutSuccess',
    components:{
    
     PaymentForm,
    },
    data(){
    this.publishableKey ='pk_live_51NSSJwCuOvqvm7g13oTBoaMW5KfJnD3WfYARLhyTBtaYA33JSE5VDtJ7Hc3ih6vuiHH8YwN6Cuhp8QmX4Pxsu1M00098byc01A'
    return{
        loading:false,
        lineItems:[{
            price:'price_1Nxc3ZCuOvqvm7g1J9Iz4XIL',
            quantity:1   
        }],
        successUrl:'https://configure.atella.ca/#/book-appointment',
        cancelUrl:'https://configure.atella.ca/#/checkouterror'
    }
    },
    methods: {
        setPage() {
            const data = { page: 4, title: 'Make your payment', sub_title: 'Click on Pay now' }
            this.$store.commit('setConfigTab', data)
        },
        submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    
    },
    mounted() {
        this.setPage()
    
        
    }
}
</script>

<style  scoped>

</style>