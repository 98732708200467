import { render, staticRenderFns } from "./ConfigurePayment.vue?vue&type=template&id=93580956&scoped=true"
import script from "./ConfigurePayment.vue?vue&type=script&lang=js"
export * from "./ConfigurePayment.vue?vue&type=script&lang=js"
import style0 from "./ConfigurePayment.vue?vue&type=style&index=0&id=93580956&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93580956",
  null
  
)

export default component.exports